<template>
  <v-row justify="center">
    <v-dialog
      persistent
      scrollable
      v-model="dialog"
      max-width="800">

      <v-form v-model="valid" ref="articleNotificationForm" lazy-validation id="notification-form">

      <loader v-if="isLoading"></loader>

      <v-card class="pa-5" v-else>
        <v-card-title class="text-h5">
          Uzupełnij dane dla powiadomienia
        </v-card-title>

        <div class="mb-5 mt-15">

        <v-row>
          <v-col
            cols="8">
            <DatePickerInput
              :minDate="pushNotification.minDate"
              v-model="pushNotification.sendOn"
              :labelText="'Data wysłania'" :rules="rules.required">
            </DatePickerInput>
          </v-col>
          <v-col
            cols="4">
            <time-picker
              :disabled="isTimeDisabled"
              class="mb-5 "
              v-model="pushNotification.sendOn"
              :rules="rules.required"
            ></time-picker>
          </v-col>
        </v-row>
        </div>

        <v-text-field label="Tytuł powiadomienia"
          class="ml-8 mr-8"
          v-model='pushNotification.title'
          :rules="[...rules.maxLengthTitle, ...rules.required]">
      </v-text-field>

      <v-textarea
        auto-grow
        class="ml-8 mr-8"
        label="Opis powiadomienia"
        v-model='pushNotification.description'
        :rules="[...rules.maxLengthDescription, ...rules.required]">
      </v-textarea>

      <v-file-input
        class="mr-8"
        counter
        show-size
        truncate-length="15"
        label="Plik"
        v-model="pushNotification.file"
        :rules="[...rules.fileType, ...rules.fileSize]"
        accept="image/png, image/jpeg, image/jpg">
      </v-file-input>

        <v-card-actions
          class="mt-15"
        >
          <v-spacer></v-spacer>

          <v-btn
            color="orange darken-1"
            text
            @click="dialog = false"
          >
            Anuluj
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="submitNone"
          >
            bez powiadomienia
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="submit"
          >
          Dodaj powiadomienie
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  displayErrors
} from '@/utils/helpers'
import DatePickerInput from '@/components/DatePickerInput.vue'
import Loader from '@/components/Loader.vue'
import TimePicker from '@/components/TimePicker.vue'
export default {
  name: 'article-notification-dialog',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    DatePickerInput,
    TimePicker,
    Loader
  },
  data () {
    return {
      rules: {
        required: [v => !!v || 'Pole wymagane'],
        maxLengthTitle: [v => v.length <= 128 || 'Maksymalna ilosć 128 znaków.'],
        maxLengthDescription: [v => v.length <= 512 || 'Maksymalna ilosć 512 znaków.'],
        fileType: [v => !v || !!(/(\.jpg|\.jpeg|\.png)$/i.exec(v.name)) || 'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .png'],
        fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB']
      },
      dialog: false,
      time: '07:30',
      minDate: null,
      valid: true,
      isTimeDisabled: true,
      isLoading: false
    }
  },
  computed: {
    pushNotification: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async submitNone () {
      this.pushNotification.none = true
      this.isLoading = true
      this.$emit('submit')
    },
    async submit () {
      if (!this.$refs.articleNotificationForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości.')
        return
      }

      this.isLoading = true
      this.$emit('submit')
    }
  },
  watch: {
    'pushNotification.sendOn': function (val) {
      if (val) {
        this.isTimeDisabled = false
      }
    }
  }
}
</script>

<style>
  .v-dialog{
    overflow-y: visible !important;
  }
  #notification-form{
    overflow-y: scroll;
  }
</style>
