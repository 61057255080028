<template>
  <div>
    <h2 class="pl-2">
      {{!this.isAdd ? 'Edytuj' : 'Dodaj nowy'}} konkurs
    </h2>
    <v-form v-model="valid" ref="articleForm" lazy-validation class="pb-10">
      <v-card class="mt-10 pa-5">
        <v-row>
          <v-col
            cols="9">
            <h4
              class="mb-5">
              Dane podstawowe
            </h4>
          </v-col>

          <v-col
            v-if="!this.isAdd"
            class="text-right"
            cols="3">
            <v-btn
              color="red"
              class="mr-10 white--text"
              @click="deleteArticle()"
            >
            {{ $t('common.delete') }} konkurs
            <v-icon class="ml-2">mdi-delete</v-icon>
            </v-btn>
          </v-col>
      </v-row>

      <v-row>
          <v-col cols="4">
            <v-select
                      multiple
                      ref="groupSelect"
                      :items="groups"
                      label="Grupa"
                      :placeholder="'Wszystkie'"
                      :item-text="'name'"
                      :item-value="'id'"
                      v-model='article.groupsIds'
                      persistent-placeholder
              ></v-select>
          </v-col>
          <v-col cols="4">
            <DatePickerInput v-model="article.from" :labelText="'Data od'" :rules="rules.required"></DatePickerInput>
          </v-col>
          <v-col cols="4">
            <DatePickerInput
              v-model="article.to"
              :labelText="'Data do'"
              :rules="rules.required"></DatePickerInput>
          </v-col>
        </v-row>

        <v-text-field label="Tytuł"
          v-model='article.name'
          :rules="[...rules.maxLengthTitle, ...rules.required]">
        </v-text-field>

        <v-textarea
          auto-grow
          class="mb-5"
          label="Opis podstawowy"
          v-model='article.description'
          :rules="[...rules.maxLengthDescription, ...rules.required]">
        </v-textarea>

        <span class="custom-label">Plik</span>
        <div v-if="article.fileName">
          <span class="mr-5">
            {{article.fileOriginalName}}
          </span>
          <v-btn
              class="mr-5"
              right
              @click="removeFile()">
              Usuń plik
          </v-btn>
          <v-btn class="ml-2"
              @click="openFile(article.fileName)">
              Otwrz plik w nowej karcie
          </v-btn>
        </div>
        <v-file-input v-else
          counter
          show-size
          truncate-length="15"
          label="Miniaturka"
          v-model='article.file'
          accept=".png, .jpeg, .jpg, .mp4"
          :rules="[...rules.fileType, ...rules.fileSize]">
        </v-file-input>
      </v-card>

      <v-card
        class="mt-10 pa-5"
        v-for="(item, index) in article.articleDetails"
        :key="item.id">
        <AddArticleDetails
          :articleDetails="article.articleDetails[index]"
          :index="index" @remove="removeDetails"
          :title="'szczegóły konkursu'">
        </AddArticleDetails>
      </v-card>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!article.isArchival"
          color="green"
          class="mr-4 white--text mt-5 mr-10"
          @click="addDetails">
          Dodaj sekcję szczegółów
          <v-icon class="ml-2">mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-actions class="justify-end">
        <v-btn
          v-if="!article.isArchival"
          :disabled="!isAdd && isSaveChangesBtnDisabled ? true : false"
          color="blue"
          class="mr-4 mt-5 mb-5 white--text mr-10"
          @click="openNotificationDialog">
          {{ !isAdd ? 'Zapisz zmiany' : 'Dodaj konkurs'}}
          <v-icon class="ml-2">mdi-archive-arrow-up</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>

    <confirm-delete-dialog
        endPoint="/Article/DeleteArticle"
        ref="confirmDeleteDialog"
        @submit="$router.push({ name: 'Contest'})"
      ></confirm-delete-dialog>
    <article-notification-dialog
      v-model="pushNotification"
      ref="articleNotificationDialog"
      @submit="submit">
    </article-notification-dialog>
  </div>
</template>

<script>
import {
  displayInfo,
  displayErrors
} from '@/utils/helpers'
import ConfirmDeleteDialog from '@/components/Dialogs/ConfirmDeleteDialog'
import DatePickerInput from '@/components/DatePickerInput.vue'
import AddArticleDetails from '@/views/Article/AddArticleDetails.vue'
import ArticleNotificationDialog from '@/views/Article/ArticleNotificationDialog.vue'
import { ArticleTypesEnum } from '@/utils/enums.js'

export default {
  props: ['type'],
  name: 'AddOrEditContest',
  components: {
    DatePickerInput,
    AddArticleDetails,
    ConfirmDeleteDialog,
    ArticleNotificationDialog
  },
  data: () => ({
    rules: {
      required: [v => !!v || 'Pole wymagane'],
      maxLengthTitle: [v => v.length <= 256 || 'Maksymalna ilosć 256 znaków.'],
      maxLengthDescription: [v => v.length <= 4096 || 'Maksymalna ilosć 4096 znaków.'],
      fileType: [v => !v || !!(/(\.jpg|\.jpeg|\.mp4|\.gif|\.png)$/i.exec(v.name)) || 'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .mp4, .gif, .png'],
      fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB']
    },
    isAdd: false,
    isSaveChangesBtnDisabled: false,
    valid: false,
    menu: false,
    article: {
      id: null,
      version: null,
      groupsIds: [],
      name: '',
      description: '',
      file: null,
      fileName: null,
      fileOriginalName: null,
      type: 2,
      from: '',
      to: '',
      isArchival: false,
      articleDetails: []
    },
    articleOrginal: null,
    groups: [],
    pushNotification: {
      isAdd: false,
      none: false,
      sendOn: '',
      title: '',
      description: '',
      file: null,
      minDate: null
    }
  }),
  async created () {
    this.ArticleTypesEnum = ArticleTypesEnum
    const id = this.$route.params.id
    this.article.type = this.type

    if (id) {
      this.article.id = id
      await this.getDataAsync()
    }

    this.isAdd = this.article.id === undefined ||
      this.article.id == null ||
      this.article.id === ''

    const groupsResult = await this.$http.get('/Group/GetGroups')
    this.groups = groupsResult.data
  },
  methods: {
    async getDataAsync () {
      const result = await this.$http.get(`/Article/GetArticle?Id=${this.article.id}`)
      this.article = result.data
      this.articleOrginal = JSON.stringify(result.data)
    },
    addDetails () {
      this.article.articleDetails.push({
        id: null,
        name: '',
        description: '',
        file: null,
        fileName: null,
        fileOriginalName: null
      })
    },
    removeDetails (index) {
      this.article.articleDetails.splice(index, 1)
    },
    removeFile () {
      this.article.fileName = null
      this.article.fileOriginalName = null
    },
    openFile (fileName) {
      window.open(`${process.env.VUE_APP_BASE}uploads/${fileName}`, '_blank')
    },
    deleteArticle () {
      this.$refs.confirmDeleteDialog.dialog = true
      this.$refs.confirmDeleteDialog.id = this.article.id
    },
    openNotificationDialog () {
      if (this.isArticleNotChanged()) {
        displayInfo('Brak zmian do zapisania', 'info')
        return
      }

      this.pushNotification.none = false
      this.$refs.articleForm.validate()
      const now = new Date().toISOString()
      this.pushNotification.minDate = this.article.from < now ? now : this.article.from
      this.pushNotification.isAdd = this.isAdd

      if (!this.$refs.articleForm.validate()) {
        displayErrors('Uzupełnij wymagane pola lub popraw błędne wartości.')
        return
      }
      this.$refs.articleNotificationDialog.dialog = true
    },
    isArticleNotChanged () {
      return this.articleOrginal === JSON.stringify(this.article)
    },
    async submit () {
      const formData = new FormData()

      formData.append('Article.Id', this.article.id)
      formData.append('Article.Name', this.article.name)
      formData.append('Article.Description', this.article.description)
      formData.append('Article.File', this.article.file)
      formData.append('Article.FileName', this.article.fileName ? this.article.fileName : '')
      formData.append('Article.Type', this.article.type)
      formData.append('Article.From', this.article.from)
      formData.append('Article.To', this.article.to)

      if (!this.isAdd) {
        formData.append('Article.Version', this.article.version)
      }

      for (let i = 0; i < this.article.groupsIds.length; i++) {
        formData.append(`Article.GroupsIds[${i}]`, this.article.groupsIds[i])
      }

      for (let i = 0; i < this.article.articleDetails.length; i++) {
        formData.append(`Article.ArticleDetails[${i}].Name`, this.article.articleDetails[i].name)
        formData.append(`Article.ArticleDetails[${i}].Description`, this.article.articleDetails[i].description)
        formData.append(`Article.ArticleDetails[${i}].File`, this.article.articleDetails[i].file)
        formData.append(`Article.ArticleDetails[${i}].FileName`, this.article.articleDetails[i].fileName ? this.article.articleDetails[i].fileName : '')
      }

      if (this.pushNotification.none === false) {
        formData.append('Article.PushNotification.Title', this.pushNotification.title)
        formData.append('Article.PushNotification.Description', this.pushNotification.description)
        formData.append('Article.PushNotification.File', this.pushNotification.file)
        formData.append('Article.PushNotification.SendOn', new Date(this.pushNotification.sendOn).toISOString())
      }

      try {
        const result = await this.$http.post(
          this.isAdd
            ? '/Article/AddArticle'
            : '/Article/UpdateArticle', formData
        )

        if (result.status === 200) {
          this.$refs.articleNotificationDialog.dialog = false
          const info = `Poprawnie ${this.isAdd ? 'dodano' : 'zaktualizowano'} ofertę: ${this.article.name}`
          displayInfo(info)

          await this.$router.push({
            name: 'Contest'
          }
          )
        } else {
          displayErrors('Wystapił błąd')
        }
      } catch (e) {
      } finally {
        this.submitLoading = false
      }

      if (this.$refs.articleNotificationDialog?.isLoading) {
        this.$refs.articleNotificationDialog.isLoading = false
      }
    }
  },
  watch: {
    article: {
      handler () {
        if (!this.isAdd) {
          if (!this.isArticleNotChanged()) {
            this.isSaveChangesBtnDisabled = false
          } else {
            this.isSaveChangesBtnDisabled = true
          }
        }
      },
      deep: true
    }
  }
}
</script>
