<template>
    <div >
        <h4 :class="index == 0 ? 'mb-10': ''">
          {{index > 0 ? "Dodatkowe" : "" }}
          {{ title === undefined ? "Szczegóły oferty" : title }}
          {{index > 0 ? index : "" }}
        </h4>

        <v-btn
            v-if="index > 0"
            color="orange"
            class="float-right mr-10 white--text"
            @click="remove()">
            Usuń sekcje
            <v-icon class="ml-2">mdi-window-close</v-icon>
        </v-btn>
          <div style="clear:both"></div>

        <v-text-field
            label="Tytuł"
            v-model='articleDetails.name'
            :rules="[...rules.maxLengthTitle, ...rules.required]"
        ></v-text-field>

        <v-textarea
            auto-grow
            label="Opis"
            v-model='articleDetails.description'
            :rules="[...rules.maxLengthDescription, ...rules.required]"
        ></v-textarea>

        <span class="custom-label">Plik</span>
        <div v-if="articleDetails.fileName">
          <span class="mr-5">
            {{articleDetails.fileOriginalName}}
          </span>
          <v-btn
              class="mr-5"
              right
              @click="removeFile()">
              Usuń plik
          </v-btn>
          <v-btn class="ml-2"
              @click="openFile(articleDetails.fileName)">
              Otwrz plik w nowej karcie
          </v-btn>
        </div>
        <v-file-input
            v-else
            counter
            show-size
            truncate-length="15"
            label="Wybierz Plik"
            v-model='articleDetails.file'
            accept=".png, .jpeg, .jpg, .mp4"
            :rules="[...rules.fileType, ...rules.fileSize]">
        </v-file-input>

        <v-text-field ref="inputRef"
            v-if="showMultimediaUrl"
            :disabled="disabledMultimedia()"
            label="Link do multimediów"
            :rules="[...rules.httpLink]"
            v-model='articleDetails.multimediaUrl'
        ></v-text-field>
    </div>
</template>

<script>

export default ({
  props: {
    index: {
      typeof: Number,
      default: 0
    },
    articleDetails: {
      name: '',
      description: '',
      file: null,
      fileOriginalName: '',
      fileName: '',
      multimediaUrl: null
    },
    title: String,
    showMultimediaUrl: Boolean
  },
  data: () => ({
    rules: {
      required: [v => !!v || 'Pole wymagane'],
      maxLengthTitle: [v => v?.length <= 256 || 'Maksymalna ilosć 256 znaków.'],
      maxLengthDescription: [v => v.length <= 4096 || 'Maksymalna ilosć 4096 znaków.'],
      fileType: [v => !v || !!(/(\.jpg|\.jpeg|\.mp4|\.gif|\.png)$/i.exec(v.name)) || 'Błędny plik. Dozwolone rozszerzenie to .jpg, .jpeg, .mp4, .gif, .png'],
      httpLink: [v => !v || !!(/https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/i.exec(v)) || 'Błędny adres. przykładowy adres: https://forms.office.com/'],
      fileSize: [v => !v || v.size < 30000000 || 'Wielkość pliku musi być mniejsza od 30MB']
    }
  }),
  methods: {
    disabledMultimedia () {
      if (this.articleDetails.file?.name !== undefined && this.articleDetails.file?.name !== null) {
        if (this.articleDetails.file.name.includes('jpg') || this.articleDetails.file.name.includes('png')) {
          return false
        }
      }
      if (this.articleDetails.fileOriginalName !== null) {
        if (this.articleDetails.fileOriginalName.includes('jpg') || this.articleDetails.fileOriginalName.includes('png')) {
          return false
        }
      }
      this.articleDetails.multimediaUrl = null
      if (this.$refs.inputRef !== null && this.$refs.inputRef !== undefined) {
        this.$refs.inputRef.reset()
      }
      return true
    },
    remove () {
      this.$emit('remove', this.index)
    },
    removeFile () {
      this.articleDetails.fileName = null
      this.articleDetails.fileOriginalName = null
    },
    openFile (fileName) {
      window.open(`${process.env.VUE_APP_BASE}uploads/${fileName}`, '_blank')
    }
  }
})
</script>
